<div id="footer">
    <!-- Copyright -->
    <div class="text-center">
      <div class="icons">

      <a class="links-img"><img src="../assets/images/uhs_logo.png" style="height:40px; width: auto;"/></a>
      <a class="links-img"><img src="../assets/images/hct_font.png" style="height:40px; width: auto;"/></a>
      </div>
        <div class="icon-container">
          <div class="icons">
            <a href="https://twitter.com/HealthCareTech6" class="links"><i class="fab fa-twitter" aria-hidden="true"></i></a>
            <a href="https://linkedin.com/" class="links"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a>
            <a href="mailto:julian.finkenzeller@outlook.de" class="links"><i class="fas fa-at"aria-hidden="true"></i></a>
          </div>
          <div class="icons">
          <a class="links-menu" routerLink="">Home</a>
        <a class="links-menu" routerLink="contact">Contact</a>
        <a class="links-menu" routerLink="imprint">Imprint</a>
          </div>
    <div>
</div>