<nav class="navbar navbar-default sticky">
  <div class="container-fluid sticky">
    <div class="navbar-header">
      <!--
      <a class="navbar-brand" href="#">
        <img src="../assets/images/uhs_logo.png" />
      </a>
      <a class="navbar-brand" href="#">
        <img src="../assets/images/hct_font.png" />
      </a>-->
      <div class="icons">

        <a class="links-img"><img src="../assets/images/uhs_logo.png" style="height:40px; width: auto;"/></a>
        <a class="links-img"><img src="../assets/images/hct_font.png" style="height:40px; width: auto;"/></a>
        </div>
    </div>
    <div class="spacer"></div>
    <div class="icons">
      <a class="links-menu" routerLink="">Home</a>
      <a class="links-menu" routerLink="contact">Contact</a>
      <a class="links-menu" routerLink="imprint">Imprint</a>
    </div>
  </div>
</nav>
