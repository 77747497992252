<div class="carousel">
  <carousel interval="10000" pauseOnFocus="true">
    <slide>
      <img
        src="assets/images/hct.png"
        alt="first slide"
        style="display: block; width: 100%;"
      />
      <div class="carousel-caption d-none d-md-block">
        <h3>Health</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </div>
    </slide>
    <slide>
      <img
        src="https://valor-software.com/ngx-bootstrap/assets/images/nature/5.jpg"
        alt="second slide"
        style="display: block; width: 100%;"
      />
      <div class="carousel-caption d-none d-md-block">
        <h3>Communications</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
    </slide>
    <slide>
      <img
        src="https://valor-software.com/ngx-bootstrap/assets/images/nature/6.jpg"
        alt="third slide"
        style="display: block; width: 100%;"
      />
      <div class="carousel-caption d-none d-md-block">
        <h3>Technology</h3>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
      </div>
    </slide>
  </carousel>
</div>
