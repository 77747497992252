
<div class="carousel">
    <div class="contact text-center py-3">
        <br>
        <i class="far fa-envelope"></i>
      <a class="navbar-item" href="mailto:julian.finkenzeller@outlook.de" style="padding-left: 8px;">  julian.finkenzeller@outlook.de</a>
      <br>
      <br>
    <!-- Copyright -->
    <div>
</div>
